//General colors in project
$primary-color: #749204;
$secondary-color: #5f7803;
$green-1: #8fb502;
$white: #ffffff;
$gray-1: #2f3031;
$gray-2: #515253;
$gray-3: #c1c5c8;
$gray-4: #d7dde6;
$gray-5: #e8eaed;
$gray-6: #e6ecf5;
$gray-7: #f5f8fc;
$gray-8: #e5ecf5;
$gray-9: #f2f4f6;
$gray-10: #e2e3e7;
$black: #000000;
$red: #f74141;
$yellow: #ffca28;
$green: #2fb966;
$blue: #2d5081;
$default-font: Roboto, sans-serif;
$primary-button-color: #198746;
