@import '../../settings/themes/generalThemes.style';

.btn-go-back {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.go-to {
  display: flex;
  align-items: center;
}

.btn-edit {
  display: flex;
  align-items: center;
  cursor: pointer;

  a {
    display: grid;
    grid-template-columns: max-content auto;
    align-items: center;
    grid-column-gap: 4px;
    cursor: pointer;
  }
}

.text-button {
  background-color: $white;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    white-space: nowrap;
  }

  &__icon {
    display: flex;
    margin-right: 5px;
  }
}

.text-button:focus {
  outline: 0;
}

.validate-expression-button {
  display: flex !important;
  align-items: center;
  justify-content: center;

  &__icon {
    margin-right: 5px;
    display: flex !important;
  }

  &--valid {
    color: $white !important;
    background-color: $primary-button-color !important;
  }

  &--validate {
    color: $gray-1 !important;
    background-color: $gray-9 !important;
  }

  &--invalid {
    color: $white !important;
    background-color: $red !important;
  }
}

.validate-expression-button:hover,
.validate-expression-button:focus {
  border-color: inherit;
}

.glyph-redirect {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  justify-content: flex-start;
  &__click {
    @extend .glyph-redirect;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    &__disabled {
      cursor: not-allowed;
    }
  }
}

.sendMailButton {
  width: 124px !important;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 6px;
  @include btn($primary-button-color, $primary-button-color, $white);
  &--disable {
    @include btn($gray-3, $gray-3, $gray-3);
    button {
      @include btn($gray-3, $gray-3, $gray-3);
    }
  }
}

.clone-template-button-wrapper {
  display: grid;
  grid-row-gap: 10px;
  align-items: flex-end;
  justify-items: flex-start;
}

.tooltip-plans {
  .ant-tooltip-inner {
    min-width: auto;
  }
}

.download-button {
  cursor: pointer;
}
