.translate-tooltip {
  width: 130px;

  .ant-tooltip-inner {
    padding: 10px 12px;
    background: #2f3031;
    min-width: 50px !important;
  }

  &__content {
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: 8px;

    &-inner {
      display: flex;
      gap: 8px;
    }
  }
}
