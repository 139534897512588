.st-typo {
  line-height: normal;
  vertical-align: middle;

  &--heading-1 {
    font-size: 42px;
  }

  &--heading-2 {
    font-size: 30px;
  }

  &--heading-3 {
    font-size: 24px;
  }

  &--heading-4 {
    font-size: 20px;
  }

  &--heading-5 {
    font-size: 16px;
  }

  &--paragraph-1 {
    font-size: 14px;
  }

  &--paragraph-2 {
    font-size: 12px;
  }

  &--caption-1 {
    font-size: 12px;
  }

  &--caption-2 {
    font-size: 10px;
  }

  &--bold {
    font-weight: bold;
  }

  &--italic {
    font-style: italic;
  }

  &--primary {
    color: #2f3031;
  }

  &--white {
    color: '#FFFFFF';
  }

  &--secondary {
    color: #707374;
  }

  &--nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--forcewrap {
    word-spacing: 3000px;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--capitalize {
    text-transform: capitalize;
  }

  &--tagged {
    padding: 4px;
    border-radius: 4px;
  }

  &--clickable {
    cursor: pointer;
  }
}
