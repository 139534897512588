@import 'src/settings/themes/generalThemes.style';

.div-draggable {
  padding: 6px;
  margin-bottom: 3px;
}

.drag-header {
  background: $gray-5;
  border: 1px solid $gray-7;
  box-sizing: border-box;
  border-radius: 15px 15px 0px 0px;
  padding: 9px 15px;
  margin-bottom: 5px;
}

.drag-body {
  background: #ffffff;
  border: 1px $gray-5 solid;
  border-radius: 12px;
  margin-bottom: 12px;
}

.drag-header--blue {
  @extend .drag-header;
  background: $gray-6;
}
.drag-header--green {
  @extend .drag-header;
  background: $green-1;
}
.drag-header--grey {
  @extend .drag-header;
  background: $gray-2;
}

.color-white {
  color: $white;
}

.color-dark {
  color: $gray-1;
}

.header-title {
  margin-right: 13px;
}

.drag-parent {
  margin-bottom: 5px;
}

.ant-collapse-content-box {
  background: #ffffff;
  border-bottom: 1px solid #f5f5f5;
}

.ant-collapse {
  border-radius: 15px 15px 0px 0px !important;
}

.btn-green-primary {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  &:hover {
    background-color: $secondary-color !important;
    border-color: $secondary-color !important;
  }
  &:focus {
    @extend :hover;
  }
  &:active {
    @extend :hover;
  }
}

.btn-gray-primary {
  background-color: $gray-1 !important;
  border-color: $gray-1 !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  color: $white !important;
  &:hover {
    background-color: $gray-2 !important;
    border-color: $gray-2 !important;
  }
  &:focus {
    @extend :hover;
  }
  &:active {
    @extend :hover;
  }
}

.btn-gray-secondary {
  background-color: $gray-4 !important;
  border-color: $gray-4 !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  color: $gray-2 !important;
  &:hover {
    background-color: $gray-7 !important;
    border-color: $gray-7 !important;
  }
  &:focus {
    @extend :hover;
  }
  &:active {
    @extend :hover;
  }
}
.btn-gray-light {
  background-color: $gray-10 !important;
  border-color: $gray-10 !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  color: $gray-2 !important;
  &:hover {
    background-color: $gray-7 !important;
    border-color: $gray-7 !important;
  }
  &:focus {
    @extend :hover;
  }
  &:active {
    @extend :hover;
  }
}

.header-badge {
  background: grey;
  border-radius: 50%;
  padding: 4px 7px;
  color: white;
  font-weight: bold;
}

.drag-container {
  padding: 12px 6px;
  margin-bottom: 5px;
}

.drag-container--grey {
  border: 1px $gray-2 solid;
  border-radius: 0px 0px 15px 15px;
  margin: -6px 1px 5px 1px;
  background: #ffffff;
}

.drag-container--blue {
  border: 1px $gray-7 solid;
  border-radius: 0px 0px 15px 15px;
  margin: -6px 1px 5px 1px;
  background: #ffffff;
}

.drag-container--green {
  border: 1px $green-1 solid;
  border-radius: 0px 0px 15px 15px;
  margin: -6px 1px 5px 1px;
  background: #ffffff;
}

.drag-container--collapsed {
  height: 0px;
  display: none;
}

.drag-header__button {
  z-index: 10;
  cursor: pointer;
}
