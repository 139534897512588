@import '../../../settings/themes/generalThemes.style';

.page__checkbox {
  padding-left: 20px;
  padding-bottom: 12px;
}
.fp-page {
  padding: 12px 20px 16px 20px;
  background-color: $gray-7;
}

.fp-form {
  display: flex;
  flex-wrap: wrap;
}
.fp-image {
  width: 33%;
  padding: 0 8px;
  text-align: center;
}
.fp-basic-fields {
  width: 67%;
}
.fixedPointsEdit-basic-info__form {
  display: flex;
  flex-wrap: wrap;
}
.fp-input-wrap {
  padding: 0 8px;
  width: 50%;

  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-select-selection,
  .ant-select,
  .ant-form-item-children {
    display: inline-block;
    width: 100%;
  }
}

.fp-line {
  width: calc(100% - 16px);
  margin: 16px auto;
  height: 1px;
  display: block;
  background-color: #e8eaed;
}
