@import './themes.scss';

@mixin btn($background-color, $border-color, $font-color) {
  background-color: $background-color !important;
  border-color: $border-color !important;
  border-radius: 4px !important;
  color: $font-color !important;
}

@mixin btn-hover($background-color, $border-color, $font-color) {
  background-color: $background-color !important;
  border-color: $border-color !important;
  color: $font-color !important;
}

@mixin btn-rounded($background-color, $padding) {
  background: $background-color;
  display: flex;
  border-radius: 50px;
  padding: $padding;
}

@mixin flex-space-between {
  display: flex !important;
  justify-content: space-between;
}

@mixin flex-baseline {
  display: flex !important;
  align-items: baseline;
}

@mixin flex-align-center {
  display: flex !important;
  align-items: center;
}

///Default style Buttons
.ant-btn {
  color: black;
  background-color: white;
  border-color: white;
}

.ant-btn-primary {
  color: white;
  background-color: $primary-color;
  border-color: $primary-color;
}

.ant-btn-danger {
  color: $white;
  background-color: $red;
  border-color: $red;
  &:hover {
    color: $red;
    background-color: $white;
    border-color: $red;
  }
  &:active {
    @extend :hover;
  }
  &:focus {
    @extend :hover;
  }
}

.ant-message-custom-content {
  display: flex;
  text-align: left;
  padding: 10px;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-baseline {
  display: flex;
  align-items: baseline;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.text-strong {
  font-weight: bold;
}

.bt-1 {
  border-top: 1px solid;
}

.bb-1 {
  border-bottom: 1px solid;
}

.b--light-blue {
  border-color: $gray-7;
}

.mt-15 {
  margin-top: 30px;
}

.mb-15 {
  margin-bottom: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-5 {
  margin-right: 5px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-5 {
  margin-left: 5px;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.pointer {
  cursor: pointer;
}
.cursor {
  cursor: default;
}

.vertical-mid {
  vertical-align: middle;
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.flex-25 {
  flex-basis: 25%;
}

.flex-35 {
  flex-basis: 25%;
}

.flex-50 {
  flex-basis: 25%;
}

.color-red {
  color: $red;
}

.color-yellow {
  color: $yellow;
}

.color-blue {
  color: $blue;
}

.child-static:nth-child(odd) {
  background: $gray-7;
}

.child-static:nth-child(even) {
  background: $white;
}

.hide {
  visibility: hidden;
}

.round-b-15 {
  border-radius: 0px 0px 15px 15px;
}

.round-t-15 {
  border-radius: 15px 15px 0px 0px;
}

.round-15 {
  border-radius: 15px;
}

.round-50 {
  border-radius: 50%;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: $primary-button-color;
}

.ant-tabs-ink-bar {
  background-color: $primary-button-color;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: $primary-color;
}

.ant-radio-checked .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: $primary-button-color;
}

.ant-radio-inner::after {
  background-color: $primary-button-color;
}

.ant-pagination-item-active {
  background: $primary-button-color;
  border-color: $primary-button-color;
}

.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  background: $primary-button-color;
  border-color: $primary-button-color;
}

.ant-pagination-item-active a {
  color: $white;
}

.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: $white;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary-button-color;
  border-color: $primary-button-color;
}

.ant-checkbox-checked::after {
  border: 1px solid $primary-button-color;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  color: $primary-button-color;
}

.ant-tabs-nav .ant-tabs-tab:hover,
.ant-tabs-nav .ant-tabs-tab.ant-btn-danger:active,
.ant-tabs-nav .ant-tabs-tab.ant-btn-danger:focus,
.ant-tabs-nav .ant-tabs-tab.btn-save:focus,
.ant-tabs-nav .ant-tabs-tab.btn-save:active {
  color: $primary-button-color;
}
