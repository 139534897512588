@import '../../settings/themes/themes';

.confirm-box.d-flex {
  a {
    display: flex;
  }
}

.confirm-box__icon {
  position: absolute;
}
