@import '../../settings/themes/generalThemes.style';

.content-box {
  display: flex;
  justify-content: space-between;
  padding: 8px 18px;
}

.content-box__title {
  flex-basis: 45%;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: 10px;
}

.content-box__actions {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-column-gap: 54px;
}

.content-box__layout-timeline {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;

  button {
    background: none;
    border: none;
    box-shadow: none;
    padding: 0px;
  }
  button:hover,
  .ant-btn:focus,
  button:active,
  .ant-btn[disabled] {
    background: none;
    border: none;
    box-shadow: none;
  }
}

.content-box__threshold-type-group {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 24px;

  .content-box__threshold {
    width: 88px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &-valid {
      display: grid;
      grid-auto-flow: column;
      grid-column-gap: 10px;
    }
    &__text-box {
      padding: 2px 10px;
      border-radius: 6px;
      height: 25px;

      &--blue {
        background: #dae9fe;
        color: #01658d;
      }
      &--green {
        background: #d3f6d9;
        color: #198746;
      }
    }
  }
  .content-box__type {
    gap: 8px;
    display: flex;
  }
}

.content-box__action-buttons {
  display: flex;
  span {
    margin-right: 5px;
  }
}

.context-box__action-buttons__edit {
  cursor: pointer;
}

.content-box__action-buttons__move-button {
  margin-left: 15px;
  display: flex;
}

.content-box--white {
  background: $white;
}

.multiphenomenon-box__actions {
  grid-column-gap: 10px;
}

.multiphenomenon-box__threshold {
  padding-right: 54px;
}
