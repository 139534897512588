.translate {
  margin-bottom: 0;
  height: 89px;

  .ant-form-item-control-wrapper {
    top: -38px;
  }

  .translate-icon {
    float: right;
    cursor: pointer;
  }
}

.input-icon {
  margin-top: -20px;

  .icon {
    float: right;
  }
}

.input-action {
  display: grid;
  grid-template-columns: 95% 5%;
  grid-gap: 7px;
  margin-bottom: -20px;

  &_icon {
    cursor: pointer;
  }
}
