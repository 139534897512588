@import '../../../settings/themes/generalThemes.style.scss';

.action-buttons {
  @extend .action-header;
  grid-auto-flow: column;
  align-items: center;
  grid-column-gap: 8px;

  .btn-list {
    background: transparent;
    border: none !important;
    padding: 0 0 !important;
    margin-top: 2px;
  }
  &--edit > a {
    display: grid;
    grid-template-columns: max-content auto;
    align-items: center;
    grid-column-gap: 4px;
    cursor: pointer;
  }
}

.action-header {
  display: grid;
  justify-content: flex-end;
  padding-right: 10px;
  min-height: 21px;
}

.btn--green {
  @include flex-space-between;
  @include flex-align-center;
  @include btn($primary-button-color, $primary-button-color, $white);
  &:hover {
    @include btn-hover($white, $primary-button-color, $primary-button-color);
  }
  &:focus {
    @extend :hover;
  }
  &:active {
    @extend :hover;
  }
  span {
    margin-left: 5px;
  }
  padding: 0px 16px !important;
}
